<!-- 投资考察路线 -->
<template>
  <div class="route-frame">
    <chance-detail
      :detailShow.sync="chanceShow"
      :nowData="nowChance"
    ></chance-detail>
    <el-dialog width="900px" :show-close="false" :visible.sync="detailShow">
      <template #title>
        <div class="dialog_title">
          <div class="left">{{ detail.pointName || "地点名称" }}</div>
          <i class="close el-icon-circle-close" @click="detailShow = false"></i>
        </div>
      </template>
      <div class="dialog_content">
        <div class="box">
          <div class="item">
            <div class="label">地点描述</div>
            <div class="value">
              {{ detail.pointDesc || "暂无描述" }}
            </div>
          </div>
        </div>
        <div class="box">
          <div class="item">
            <div class="label">投资机会</div>
            <div class="value" v-show="detail.investChanceList">
              <div
                class="child"
                v-for="item in detail.investChanceList"
                :key="item.id"
                @click="detailInvest(item)"
              >
                {{ item.projectName }}
              </div>
            </div>
            <div class="value" v-show="!detail.investChanceList">
              暂无相关项目
            </div>
          </div>
        </div>
        <div class="box">
          <div class="item">
            <div class="label">重点企业</div>
            <div class="value" v-show="detail.enterpriseList">
              <div
                class="child"
                v-for="item in detail.enterpriseList"
                :key="item.id"
                @click="detailEnterprise(item)"
              >
                {{ item.enterpriseName }}
              </div>
            </div>
            <div class="value" v-show="!detail.investChanceList">
              暂无相关公司
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <div class="body">
      <top showTitle="投资考察路线"></top>
      <div class="content">
        <div class="left">
          <div
            :class="{ type_item: true, active: activeType == item.id }"
            v-for="item in list"
            :key="item.id"
            @click="changeType(item)"
          >
            <div class="show">
              <div class="label">{{ item.routeName }}</div>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div
            v-show="list.length != 0"
            :class="{ type_item: true, active: activeType == 'custom' }"
            @click="changeType('custom')"
          >
            <div class="show">
              <div class="label">自定义考察路线</div>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
        <div class="center">
          <div class="center_title" v-show="!planning">
            <i class="arrow el-icon-caret-right"></i>
            <div class="center_label">{{ nowData.routeName }}</div>
          </div>
          <div class="wrapper">
            <!-- 地图上的自定义路线框架 -->
            <div class="box" v-show="planning">
              <div class="expand" @click="filterShow = !filterShow">
                <i class="el-icon-top-left" v-show="filterShow"></i>
                <i class="el-icon-bottom-right" v-show="!filterShow"></i>
              </div>
              <div :class="{ 'search-box': true, filterShow }">
                <div class="search">
                  <el-input value="成都" disabled size="mini">
                    <template slot="prepend">起</template>
                  </el-input>
                </div>
                <div class="search" v-for="item in wayPointList" :key="item.id">
                  <el-input
                    :id="item.id"
                    v-model="item.value"
                    placeholder="请输入途径点关键词"
                    size="mini"
                  >
                    <template slot="prepend">经</template>
                  </el-input>
                  <div class="btn" @click="delPointWay(item)">
                    <i class="el-icon-remove-outline"></i>
                  </div>
                </div>
                <div class="search">
                  <el-input
                    id="search"
                    v-model="endPoint.value"
                    placeholder="请输入终点关键词"
                    size="mini"
                  >
                    <template slot="prepend">终</template>
                  </el-input>
                  <div class="btn" @click="addPointWay">
                    <i class="el-icon-circle-plus-outline"></i>
                  </div>
                </div>
                <div class="search" v-show="wayPointList.length != 0">
                  <el-link
                    type="info"
                    :underline="false"
                    style="font-size: 10px"
                    @click="clearPlan"
                    >清除路线</el-link
                  >
                  <el-button type="primary" size="mini" @click="startPlan"
                    >完成</el-button
                  >
                </div>
              </div>
              <div class="search-list" id="list">
                <div class="search-item"></div>
              </div>
            </div>
            <div id="container"></div>
          </div>
        </div>
        <div class="right">
          <div class="choose">
            <div class="choice active">考察路线详情</div>
          </div>
          <div class="bottom">
            <div class="desc" v-show="!planning">
              {{ handleDesc }}
            </div>
            <!-- 指定路线 -->
            <div class="addrs" v-show="!planning">
              <!-- 判断展示哪一条路线 -->
              <div class="way-types">
                <div
                  :class="{ 'way-type': true, active: nowWay == 1 }"
                  @click="changeWay(1)"
                >
                  <img class="icon" src="@/assets/route/airplane.png" alt="" />
                </div>
                <div
                  :class="{ 'way-type': true, active: nowWay == 0 }"
                  @click="changeWay(0)"
                >
                  <img class="icon" src="@/assets/route/car.png" alt="" />
                </div>
              </div>
              <!-- 展示内容 -->
              <div class="fixed" v-show="nowData.pointList.length != 0">
                <div class="item start" v-show="nowData.id">
                  <div class="addr" :text="handleText(0)">成都</div>
                </div>
                <div class="item" v-show="nowWay == 1 && nowData.id">
                  <div class="distance">
                    <p>方式：<span>飞机</span></p>
                    <p>距离：<span>270km</span></p>
                    <p>时间：<span>65min</span></p>
                  </div>
                  <div class="addr" :text="handleText(1)">红原</div>
                </div>
              </div>
              <div class="empty" v-show="nowData.pointList.length == 0">
                暂无相关路线
              </div>
              <div
                :class="{
                  item: true,
                  end: idx == nowData.pointList.length - 1,
                }"
                @click="getDetail(item)"
                v-for="(item, idx) in nowData.pointList"
                :key="item.id"
              >
                <div class="distance">
                  <p v-show="item.vehicle">
                    方式：<span>{{ item.vehicle }}</span>
                  </p>
                  <p>
                    距离：<span>{{ item.distance }}km</span>
                  </p>
                  <p>
                    时间：<span>{{ item.time }}min</span>
                  </p>
                </div>
                <div
                  class="addr"
                  :text="handleText(nowWay == 1 ? idx + 2 : idx + 1)"
                >
                  {{ item.pointName }}
                  <img src="@/assets/route/route1.png" alt="" />
                </div>
              </div>
            </div>
            <!-- 临时自定义路线 -->
            <div
              class="addrs customAddr"
              v-show="planning && pointShowInfo.time"
            >
              <div class="describ">
                <span class="base">约 {{ handleTime }}分钟</span>
                <span class="base">{{ handleDistance }}公里</span>
              </div>
              <div
                :class="{
                  item: true,
                  start: idx == 0,
                  end: idx == pointShowInfo.list.length - 1,
                }"
                v-for="(item, idx) in pointShowInfo.list"
                :key="item"
              >
                <div class="addr" text="起" v-if="idx == 0">{{ item }}</div>
                <div
                  class="addr"
                  text="终"
                  v-else-if="idx == pointShowInfo.list.length - 1"
                >
                  {{ item }}
                </div>
                <div class="addr" text="途" v-else>{{ item }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import Bottom from "../../components/bottom.vue";
import top from "../../components/top.vue";

import AMapLoader from "@amap/amap-jsapi-loader";
import ChanceDetail from "../../components/chanceDetail.vue";
export default {
  components: { top, Bottom, ChanceDetail },
  data() {
    return {
      map: null,
      AMap: null,
      placeSearch: null,
      driving: null,
      drivingFree: null, // 自定义驾车轨迹的插件承载物
      activeType: "", // 第一级激活类
      activeSubType: "", // 第二级激活类
      activeLastType: "", // 第三级激活类
      detailShow: false,
      detail: {}, // 地点详情
      queryParams: {
        pageIndex: 1,
        pageSize: 50,
      },
      list: [],
      nowData: {
        pointList: [],
      },
      nowChance: {},
      chanceShow: false,
      nowWay: 1, // 当前选择交通方式 0自驾1飞机
      planning: false, // 判断是否在自定义路线事件中
      wayPointList: [], // 途径点list:{ idx:序号,id:inputid,value:搜索内容,path:[选点坐标] }
      endPoint: { value: "", path: [] }, // 终点
      pointShowInfo: {
        distance: 0,
        time: 0,
        list: [],
      }, // 用于展示在右边的自定义路线详情
      filterShow: true, // 自定义筛选框的展示
    };
  },
  created() {
    this.initMap();
  },
  computed: {
    handleDesc() {
      return (
        this.nowData.routeDesc && this.nowData.routeDesc.replace(/\n/g, "<br>")
      );
    },
    // 处理坐标列表的起始展示
    handleText() {
      return (idx) => {
        let res = "";
        const list = this.nowData.pointList;
        const len = list ? list.length - 1 + 2 : 0;
        if (idx == 0) {
          res = "起";
        } else if (idx == len) {
          res = "终";
        } else {
          res = idx;
        }
        return res;
      };
    },
    handleTime() {
      const min = this.pointShowInfo.time / 60;
      return min.toFixed(2);
    },
    handleDistance() {
      const distance = this.pointShowInfo.distance / 1000;
      return distance.toFixed(2);
    },
  },
  mounted() {},
  methods: {
    async getList() {
      let res = await this.$api.getRouteList(this.queryParams);
      this.list = res.data.list;
      this.changeType(this.list[0]);
    },
    changeType(item) {
      this.nowWay = 1;
      this.map.clearMap();
      if (item.id) {
        let { routPointListMap } = item;
        this.activeType = item.id;
        item.pointList =
          this.nowWay == 0 ? routPointListMap.zijia : routPointListMap.feiji;
        this.nowData = { ...item };
        this.initCover();
        this.planning = false;
      } else {
        this.driving && this.driving.clear();
        // 自定义路线
        this.activeType = item;
        this.nowData = {};
        Object.assign(this.$data.nowData, this.$options.data().nowData);
        if (this.endPoint.path.length != 0) {
          this.drivingSearch();
        }
        this.planning = true;
      }
    },
    initMap() {
      let that = this;
      AMapLoader.load({
        key: this.$mapKey,
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.Scale",
          "AMap.Driving",
          "AMap.AutoComplete",
          "AMap.PlaceSearch",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.AMap = AMap;
          this.map = new AMap.Map("container", {
            //设置地图容器id
            zoom: 14, //初始化地图级别
            center: [102.987592, 32.069268], //初始化地图中心点位置
          });
          this.map.addControl(new AMap.Scale());

          // 搜索精准地址用于添加标记
          let auto = new AMap.AutoComplete({
            input: "search",
            output: "list",
            city: "510000",
            citylimit: true,
          });
          this.placeSearch = new AMap.PlaceSearch();
          AMap.Event.addListener(auto, "select", select); //注册监听，当选中某条记录时会触发
          AMap.Event.addListener(auto, "error", onError);
          function select(e) {
            // 终点 数据选择事件
            const name = e.poi.name;
            that.endPoint.value = name;
            that.placeSearch.search(name, (status, result) => {
              const location = result.poiList.pois[0].location;
              const point = [location.lng, location.lat];
              that.endPoint.path = point;
              if (that.wayPointList.length == 0) {
                that.drivingSearch();
              }
            }); //关键字查询查询
          }
          function onError(data) {
            console.log("定位失败", data.info);
          }

          this.driving = new AMap.Driving({
            map: this.map,
            hideMarkers: true, //  取消起、终、途径点的标记
          });
          // 自定义路线规划时用
          this.drivingFree = new AMap.Driving({
            map: this.map,
          });
          // 先初始化地图再请求数据
          this.getList();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 固定路线添加覆盖物及路线规划
    initCover() {
      let { AMap, map, nowWay } = this;
      // 先清除之前的路线规划以及地图其他覆盖物
      this.driving && this.driving.clear();
      this.drivingFree && this.drivingFree.clear();
      map.clearMap();
      const cdPath = [103.951575, 30.559103]; // 成都双流机场坐标
      const hyPath = [102.354711, 32.530882]; // 红原机场坐标
      const allPathes = this.nowData.pointList;
      const len = allPathes.length;
      if (len == 0) {
        // 若暂未添加途径点则不进行渲染
        return;
      }
      let planStart = null;
      if (nowWay == 1) {
        // 红原->自定义第一个点 的路线规划开始与结束点
        planStart = new AMap.LngLat(...hyPath);
      } else {
        // 成都->自定义第一个点 的路线规划开始与结束点
        planStart = new AMap.LngLat(...cdPath);
      }
      const planEnd = new AMap.LngLat(
        allPathes[len - 1].longitude,
        allPathes[len - 1].latitude
      );
      // 红原/成都->终点 的所有途径点
      const exceptEnd = allPathes.filter((ele, idx) => idx != len - 1);
      const waypoints = exceptEnd.map((ele) => {
        return new AMap.LngLat(ele.longitude, ele.latitude);
      });
      this.driving.search(planStart, planEnd, { waypoints }, function () {
        // 整个路程所有点的标记
        let points = [];
        if (nowWay == 1) {
          points = [
            new AMap.LngLat(...cdPath), // 成都
            planStart, // 红原
            ...allPathes.map(
              (ele) => new AMap.LngLat(ele.longitude, ele.latitude)
            ), // 自定义点
          ];
        } else {
          points = [
            new AMap.LngLat(...cdPath), // 成都
            ...allPathes.map(
              (ele) => new AMap.LngLat(ele.longitude, ele.latitude)
            ), // 自定义点
          ];
        }
        const pointsLen = points.length;
        points.forEach((ele, idx) => {
          let startIcon = null;
          let marker = null;
          if (idx == 0) {
            // 起点
            startIcon = new AMap.Icon({
              size: new AMap.Size(30, 30),
              imageSize: new AMap.Size(25, 25),
              image: require("../../assets/route/start.png"),
            });
            marker = new AMap.Marker({
              icon: startIcon,
              position: ele,
              offset: new AMap.Pixel(-10, -20),
            });
          } else if (idx == pointsLen - 1) {
            // 终点
            startIcon = new AMap.Icon({
              size: new AMap.Size(30, 30),
              imageSize: new AMap.Size(25, 25),
              image: require("../../assets/route/end.png"),
            });
            marker = new AMap.Marker({
              icon: startIcon,
              position: ele,
              offset: new AMap.Pixel(-10, -20),
            });
          } else {
            // 途径点
            let div = document.createElement("div");
            div.style.cssText =
              "width:20px;height:20px;line-height:20px;border-radius:50%;background:#ff7e1f;color:#fff;font-size:14px;text-align:center";
            div.innerHTML = idx;
            marker = new AMap.Marker({
              position: ele,
              content: div,
              offset: new AMap.Pixel(-13, -20),
            });
          }
          marker.setMap(map);
        });
        if (nowWay == 1) {
          // 绘制飞机轨迹 成都-红原
          const lineArr = [cdPath, hyPath];
          let polyline = new AMap.Polyline({
            path: lineArr,
            zIndex: 1,
            showDir: true,
            strokeWeight: 6, //线宽
          });
          polyline.setMap(map);
        }

        map.setFitView();
      });
    },
    getDetail(item) {
      this.detailShow = true;
      this.detail = item;
    },
    // 查看投资机会详情
    detailInvest(item) {
      this.nowChance = item;
      this.chanceShow = true;
    },
    // 前往重点企业详情
    detailEnterprise(item) {
      this.$router.push("/move/enterprise?name=" + item.enterpriseName);
    },
    // 更改主要交通工具
    changeWay(type) {
      if (this.nowWay == type) {
        return;
      }
      this.driving.clear();
      let { routPointListMap } = this.nowData;
      this.nowWay = type;
      this.nowData.pointList =
        type == 0 ? routPointListMap.zijia : routPointListMap.feiji;
      this.initCover();
    },
    // 自定义路线规划事件
    drivingSearch() {
      let that = this;
      this.map.clearMap();
      const planStart = [103.951575, 30.559103]; // 成都双流机场坐标
      const planEnd = this.endPoint.path;
      this.wayPointList = this.wayPointList.filter(
        (ele) => ele.path.length != 0 && ele.value
      );
      const waypoints = this.wayPointList.map((ele) => ele.path);
      setTimeout(() => {
        this.drivingFree.search(
          planStart,
          planEnd,
          { waypoints },
          function (s, r) {
            const info = r.routes[0];
            that.pointShowInfo.distance = info.distance;
            that.pointShowInfo.time = info.time;
            that.pointShowInfo.list = [
              "成都",
              ...that.wayPointList.map((ele) => ele.value),
              that.endPoint.value,
            ];
          }
        );
      }, 500);
    },
    // 添加途径点
    addPointWay() {
      let that = this;
      const len = this.wayPointList.length;
      let lastIdx = 0;
      if (len > 0) {
        lastIdx = this.wayPointList[len - 1].idx;
      }
      const id = `search${lastIdx + 1}`;
      this.wayPointList.push({
        idx: lastIdx + 1,
        id,
        value: "",
        path: [],
      });
      setTimeout(() => {
        // 搜索精准地址用于添加标记
        let auto = new this.AMap.AutoComplete({
          input: id,
          output: "list",
          city: "510000",
          citylimit: true,
        });
        this.AMap.Event.addListener(auto, "select", select); //注册监听，当选中某条记录时会触发
        this.AMap.Event.addListener(auto, "error", onError);
        function select(e) {
          // 途径点 数据选择事件
          that.wayPointList[lastIdx].value = e.poi.name;
          that.placeSearch.search(e.poi.name, (status, result) => {
            const location = result.poiList.pois[0].location;
            const point = [location.lng, location.lat];
            that.wayPointList[lastIdx].path = point;
          }); //关键字查询查询
        }
        function onError(data) {
          console.log("定位失败", data.info);
        }
      }, 500);
    },
    // 删除途径点
    delPointWay(item) {
      const idx = this.wayPointList.findIndex((ele) => ele.id == item.id);
      this.wayPointList.splice(idx, 1);
    },
    startPlan() {
      this.drivingSearch();
    },
    clearPlan() {
      this.drivingFree.clear();
      Object.assign(this.$data.endPoint, this.$options.data().endPoint);
      this.wayPointList = [];
      Object.assign(
        this.$data.pointShowInfo,
        this.$options.data().pointShowInfo
      );
    },
  },
  beforeDestroy() {
    this.map && this.map.destroy();
  },
};
</script>

<style lang='scss' scoped>
.route-frame {
  background-image: url("../../assets/route_bg.png");
  background-size: 100% 100%;
}
#panel {
  position: fixed;
  background-color: white;
  max-height: 90%;
  overflow-y: auto;
  top: 10px;
  right: 10px;
  width: 280px;
}
#panel .amap-lib-driving {
  border-radius: 4px;
  overflow: hidden;
}
.dialog_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 20px;

  .left {
    padding-left: 15px;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 3px;
      height: 60%;
      border-radius: 5px;
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .close {
    cursor: pointer;
    font-size: 25px;
  }
}
.dialog_content {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
  color: #666;

  .box {
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
    font-size: 14px;

    .item {
      padding: 13px 0;
      display: flex;
      background-color: #f5f8fd;
      line-height: 24px;

      &:nth-child(2n-1) {
        background-color: #f2f5fc;
      }

      .label {
        width: 110px;
        padding-left: 20px;
        box-sizing: border-box;
        flex-shrink: 0;
      }
      .value {
        color: #333;
        flex-grow: 1;
        padding-left: 10px;
        word-break: break-all;

        .child {
          width: fit-content;
          border-bottom: 1px solid #efefef;
          color: $theme;
          cursor: pointer;
          margin: 10px 0;

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
.content {
  width: 100%;
  min-width: 1100px;
  background-color: rgb(250 251 253 / 70%);
  position: relative;
  box-shadow: 0 2px 5px rgba($color: #aaa, $alpha: 0.3);
  padding: 34px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  .left {
    width: 300px;
    margin-right: 20px;

    .type_item {
      background-color: #fff;
      padding: 10px;
      margin-bottom: 10px;
      color: #cfcfcf;
      cursor: pointer;
      &:hover {
        color: $theme;
        .show {
          .label {
            color: $theme;
          }
          &::before {
            background-color: $theme;
          }
        }
      }

      .show {
        padding: 0 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        &::before {
          content: "";
          width: 3px;
          height: 60%;
          border-radius: 10px;
          background-color: #cfcfcf;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        .label {
          padding-left: 10px;
          color: #333;
        }
        .minus {
          display: none;
        }
      }
    }

    .active {
      font-weight: bold;
      color: $theme;

      .sub_list {
        max-height: 1000px;
      }
      .show {
        &::before {
          background-color: $theme;
        }
        .label {
          color: $theme;
        }
        .plus {
          display: none;
        }
        .minus {
          display: block;
        }
      }
    }
  }
  .center {
    flex-grow: 1;
    position: relative;

    #container {
      width: 100%;
      height: 100%;
    }

    &_title {
      width: 95%;
      height: 40px;
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
      background-color: rgba(246, 251, 255, 0.7);
      border: 1px solid #fff;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .arrow {
        color: $theme;
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
      .center_label {
        margin-left: 35px;
        font-weight: bold;
        font-size: 18px;
      }

      .custom {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        user-select: none;
      }
    }
    .wrapper {
      height: 100%;

      .box {
        z-index: 11;
        position: absolute;
        left: 10px;
        top: 10px;

        .expand {
          z-index: 12;
          position: absolute;
          left: -10px;
          top: -10px;
          background-color: #fff;
          border-radius: 4px;
          width: 20px;
          height: 20px;
          text-align: center;
          line-height: 20px;
          box-shadow: 0 0 5px rgba($color: #000, $alpha: 0.3);
          cursor: pointer;
          transition: all 0.5s;

          &:hover {
            transform: scale(1.2);
          }

          i {
            font-size: 12px;
          }
        }
      }

      .search-box {
        background-color: #fff;
        border-radius: 4px;
        padding: 10px;
        transform: scale(0);
        transform-origin: left top;
        transition: transform 0.5s;

        .search {
          display: flex;
          align-items: center;
          margin-bottom: 5px;

          &:last-child {
            justify-content: space-between;
          }
          &:nth-child(2) {
            margin-top: 5px;
          }

          .el-input {
            width: 200px;
          }

          .btn {
            margin-left: 10px;
            cursor: pointer;

            &:hover {
              color: #0e5acd;
            }
          }
        }
      }
      .filterShow {
        transform: scale(1);
      }
      .search-list {
        width: 100%;
        height: 200px;
        background-color: #fff;
        position: absolute;
        top: 100%;
        left: 0;
        overflow: auto;
        border-top: 1px solid #eee;
      }
    }

    ::v-deep .el-input__inner {
      border-radius: 4px;
    }
    ::v-deep .el-input-group--prepend .el-input__inner {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    ::v-deep .el-input-group__prepend {
      padding: 0 10px;
    }
  }
  .right {
    width: 500px;
    margin-left: 20px;
    background-color: #fff;
    padding: 10px 15px;
    box-sizing: border-box;

    .choose {
      height: 40px;
      border: 1px solid #eee;
      border-radius: 5px;
      display: flex;
      justify-content: space-evenly;
      font-size: 18px;
      line-height: 40px;

      .choice {
        height: 100%;
        cursor: pointer;
        position: relative;

        &:hover {
          color: $theme;

          &::after {
            width: 100%;
          }
        }
        &::after {
          content: "";
          display: block;
          width: 0;
          height: 2px;
          border-radius: 5px;
          background-color: $theme;
          position: absolute;
          left: 0;
          bottom: -1px;
          transition: width 0.2s;
        }
      }
      .active {
        font-weight: bold;
        color: $theme;

        &::after {
          width: 100%;
        }
      }
    }
    .bottom {
      max-height: calc(100% - 67px);
      overflow: auto;
      font-size: 14px;
      margin-top: 25px;
      padding: 0 5px;
      .desc {
        text-align: justify;
        padding-bottom: 15px;
        border-bottom: 1px solid #efefef;
      }
      .addrs {
        min-height: 30px;
        padding-top: 15px;
        padding-left: 40px;
        position: relative;

        .way-types {
          position: absolute;
          right: 10px;
          top: 5px;
          display: flex;

          .way-type {
            width: 50px;
            height: 25px;
            border: 1px solid #eee;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:last-child {
              border-left: 0;
            }

            .icon {
              width: 15px;
            }
          }
          .active {
            background-color: #eee;
          }
        }
        .start {
          .addr {
            color: $theme;
            &::before {
              background-color: $theme;
            }
          }
        }
        .end {
          .addr {
            color: #f82f20;
            &::before {
              background-color: #f82f20;
            }
          }
        }

        .empty {
          font-size: 12px;
          color: #666;
        }

        .addr {
          width: fit-content;
          color: #ff7e1f;
          font-size: 16px;
          cursor: pointer;
          position: relative;

          &::before {
            content: attr(text);
            width: 18px;
            height: 18px;
            color: #fff;
            font-size: 10px;
            text-align: center;
            line-height: 18px;
            background-color: #ff7e1f;
            border-radius: 50%;
            position: absolute;
            left: -40px;
            top: 50%;
            transform: translateY(-50%);
          }

          img {
            width: 18px;
            margin-left: 10px;
            vertical-align: middle;
          }
        }
        .distance {
          border-radius: 20px;
          background-color: #f0f4f9;
          padding: 8px 25px;
          margin: 15px 0 15px -30px;
          display: flex;
          flex-wrap: wrap;

          p {
            white-space: nowrap;
          }
          span {
            margin-right: 15px;
            color: $theme;
          }
        }
        .describ {
          margin-bottom: 20px;
          .base {
            padding: 0 12px;
            border-left: 1px solid #3c3d3f;

            &:first-child {
              border-left: 0;
            }
          }
        }
      }
      .customAddr {
        .item {
          margin-bottom: 20px;
        }
      }
    }
  }
}
@media (max-width: 1700px) {
  .content .right {
    width: 400px;

    .choose {
      font-size: 15px;
    }
    .desc {
      font-size: 13px;
    }
  }
}
@media (max-width: 1500px) {
  .content {
    .left {
      width: 200px;

      .type_item {
        font-size: 14px;
      }
    }
    .right {
      width: 300px;

      .bottom .addrs {
        padding-left: 30px;

        .addr {
          font-size: 14px;
          &::before {
            left: -30px;
          }
        }
        .distance {
          // padding: ;
          margin: 10px 0;
          font-size: 10px;
        }
      }
    }
  }
}
</style>

